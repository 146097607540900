import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Download videos, questions, rubrics and more from your CalcMedic course! Perfect for when your WiFi keeps cutting out.    `}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{}}>{`./calcmedic-download [authorizationToken] [blocksAccessToken] [schoolId]
./calcmedic-download [harFile]

Run this from the directory that you want data to be downloaded to.

It's recommended that you use a HAR file to log in to the scraper. To do this, open the Chrome DevTools network panel, reload a calcmedic page, and click "Export HAR..." (the "download" button is the last button in the row right below the network tab). Provide the path to this file as the first argument.

If you want to manually provide request parameters, you can:
    authorizationToken should be the token provided in the authorization header (without the Bearer part), eg. eyJ0eXAiOiJ...
    blocksAccessToken should be the token provided in the x-blocks-access-token, eg. eyJhbGciOiJ...
    schoolId should be the ID of your school, found in the school_id query parameter 
`}</code></pre>
    <h2>{`Source`}</h2>
    <p>{`Source code can be found `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/calcmedic-download"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      